<style lang="scss" scoped>
.box {
  position: fixed;
  right: 16px;
  top: 128px;
  z-index: 2;

  .box-main {
    width: 0px;
    transition: 0.5s all;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    // padding-bottom: 37px;
    overflow: hidden;
    .main {
      width: 300px;

      .title {
        width: 96px;
        justify-content: right;
        height: 43px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 18px;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: bold;
        margin-right: 16px;
      }
      .data-item {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .data {
          line-height: 18px;
          font-size: 13px;
          color: rgba(0, 0, 0, 0.8);
          margin-left: 16px;
        }
        .icon {
          margin-left: 32px;
        }
      }
      .data-item:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.box-help {
  width: 22px;
  height: 56px;
  position: absolute;
  left: -22px;
  top: 12px;
  background-image: url("../../../../assets/features/hidden.svg");
  cursor: pointer;
  &#show {
    background-image: url("../../../../assets/features/show.svg");
  }
}

#showBox {
  width: 300px;
  padding-bottom: 12px;
}

#left0 {
  right: 0;
}
.data-main {
  max-height: calc(100vh - 144px - 24px - 53px - 37px - 54px);
  overflow-y: auto;
}
</style>
<template>
  <div class="feature">
    <div class="box" :id="showBox ? '' : 'left0'">
      <div
        class="box-help"
        @click="showBox = !showBox"
        :id="showBox ? '' : 'show'"
      ></div>
      <div class="box-main" :id="showBox ? 'showBox' : ''">
        <div class="main">
          <div class="title">Features</div>
          <div class="data-main">
            <div class="data-item" v-for="item of tableDatas">
              <img :src="item.img" class="icon" alt="" />
              <div class="datas">
                <div class="data" v-for="i of item.datas">{{ i }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBox: true,
    };
  },
  computed: {
    tableDatas() {
      let data;
      if (this.$store.state.meshType == "LFP9-30kWh/HV") {
        data = [
          {
            img: require("../../../../assets/features/1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("../../../../assets/features/2.svg"),
            datas: ["Home Automation"],
          },
          {
            img: require("../../../../assets/features/3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("../../../../assets/features/4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("../../../../assets/features/5.svg"),
            datas: ["Communication"],
          },
          {
            img: require("../../../../assets/features/6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("../../../../assets/features/7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("../../../../assets/features/8.svg"),
            datas: ["IP54 RatingTriple hardware protection"],
          },
          {
            img: require("../../../../assets/features/9.svg"),
            datas: ["Smart BMS system to", "optimize the performance"],
          },
        ];
      } else if (this.$store.state.meshType == "LFP 5-10KWH/LV") {
        data = [
          {
            img: require("../../../../assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("../../../../assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("../../../../assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("../../../../assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("../../../../assets/features/1-5.svg"),
            datas: ["Scalable up to 160kWh 16 (Parallel)"],
          },
          {
            img: require("../../../../assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("../../../../assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("../../../../assets/features/1-8.svg"),
            datas: ["IP65 RatingTriple hardware protection"],
          },
          {
            img: require("../../../../assets/features/1-9.svg"),
            datas: ["Smart BMS system to", "optimize the performance"],
          },
        ];
      } else if (this.$store.state.meshType == "Three Phase"||this.$store.state.meshType == "Single Phase") {
        data = [
          {
            img: require("../../../../assets/features/3-1.svg"),
            datas: ["Big lcd display"],
          },
          {
            img: require("../../../../assets/features/3-2.svg"),
            datas: ["Power export limit"],
          },
          {
            img: require("../../../../assets/features/3-3.svg"),
            datas: [this.$store.state.mesh=='Three-Phase'?"Three phase unbalanced output":'Excellent off grid ability'],
          },
          {
            img: require("../../../../assets/features/3-4.svg"),
            datas: ["Wifi/GPRS/Lan communication optional"],
          },
          {
            img: require("../../../../assets/features/3-5.svg"),
            datas: ["Quick and easy single person installation"],
          },
          {
            img: require("../../../../assets/features/3-6.svg"),
            datas: ["Comply with international quality standards tested"],
          },
          {
            img: require("../../../../assets/features/3-7.svg"),
            datas: ["IP65 ingress protection"],
          },
        ];
      } else if (this.$store.state.meshType == "TOPCON") {
        data = [
          {
            img: require("../../../../assets/features/4-1.svg"),
            datas: ["SMBB Technology Half Cut TOPCon Cell"],
          }, {
            img: require("../../../../assets/features/4-2.svg"),
            datas: ["High Energy Performance"],
          }, {
            img: require("../../../../assets/features/4-3.svg"),
            datas: ["100% Inspection 30years Guarantee"],
          }, {
            img: require("../../../../assets/features/4-4.svg"),
            datas: ["Fire Class A"],
          }, {
            img: require("../../../../assets/features/4-5.svg"),
            datas: ["Strengthened Mechanical Load"],
          }, {
            img: require("../../../../assets/features/4-6.svg"),
            datas: ["Advanced Bifacial Efficiency"],
          },
        ];
      } else {
        data = [
          {
            img: require("../../../../assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("../../../../assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("../../../../assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("../../../../assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("../../../../assets/features/1-5.svg"),
            datas: ["Scalable up to 80kWh 16 (Parallel)"],
          },
          {
            img: require("../../../../assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("../../../../assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("../../../../assets/features/1-8.svg"),
            datas: ["IP65 RatingTriple hardware protection"],
          },
          {
            img: require("../../../../assets/features/1-9.svg"),
            datas: ["Smart BMS system to", "optimize the performance"],
          },
        ];
      }

      return data;
    },
  },
  created() {},
};
</script>